exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-abacus-tsx": () => import("./../../../src/pages/work/abacus.tsx" /* webpackChunkName: "component---src-pages-work-abacus-tsx" */),
  "component---src-pages-work-baqalat-tsx": () => import("./../../../src/pages/work/baqalat.tsx" /* webpackChunkName: "component---src-pages-work-baqalat-tsx" */),
  "component---src-pages-work-imperium-tsx": () => import("./../../../src/pages/work/imperium.tsx" /* webpackChunkName: "component---src-pages-work-imperium-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-inverse-tsx": () => import("./../../../src/pages/work/inverse.tsx" /* webpackChunkName: "component---src-pages-work-inverse-tsx" */),
  "component---src-pages-work-optimists-tsx": () => import("./../../../src/pages/work/optimists.tsx" /* webpackChunkName: "component---src-pages-work-optimists-tsx" */),
  "component---src-pages-work-productif-tsx": () => import("./../../../src/pages/work/productif.tsx" /* webpackChunkName: "component---src-pages-work-productif-tsx" */)
}

